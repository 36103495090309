import React from "react";
import { CustomCodeTour } from "../../components/CustomCodeTour";
import { PageLayout } from "../../components/PageLayout";
import { customCodeTourPageHeroImage } from "../../images";

const CustomCodeTourPage: React.FC = () => {
  return (
    <PageLayout heroBackgroundImage={`url(${customCodeTourPageHeroImage})`}>
      <CustomCodeTour />
    </PageLayout>
  );
};

export default CustomCodeTourPage;
