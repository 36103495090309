import React from "react";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";
import { Heading2 } from "./Heading2";

export const CustomCodeTour: React.FC = () => {
  return (
    <>
      <PageHeader primary="A Guided Tour" secondary="to adding custom code" />
      <Section>
        <Heading2>How to Add Custom Code to Your Translated Solution</Heading2>
        <Paragraph>
          This section is a quick overview of how a developer can add new
          features to the translated solution. It describes
        </Paragraph>
        <Heading2>Start With ...</Heading2>
        <Paragraph>
          updating the menus adding a custom control to the client adding a web
          api to the backend testing your client control testing your webAPI
        </Paragraph>
      </Section>
    </>
  );
};
